<template>
  <access-control :roles="allowedRoles" :exclude="excludedRoles">
    <slot />
    <div class="flex space-x-2">
      <template v-if="clientCanRunService && hasJsonView">
        <icon-button
          :icon="IconCurlyBraces"
          view-box="0 -3 17 24"
          :data-cy="providerName"
          :class="{ active: isJsonTreeShown(providerName) }"
          @click.stop="
            serviceJsonTreeStateStore.updateServiceJsonStatus(providerName)
          "
        />
      </template>
      <template
        v-if="
          (clientCanRunService &&
            status === COMMERCIAL_DATA_STATUS_NOT_STARTED) ||
          (clientCanRunService && buttonReloads)
        "
      >
        <slot name="button">
          <primary-button
            v-if="!isLoading"
            :disabled="confirmModalButtonDisabled"
            data-cy="run-service"
            @click.stop="showConfirmModal = true"
          >
            {{ confirmButtonText || $t("COMMON.RUN_SERVICE") }}
          </primary-button>

          <color-button
            v-else
            type="warning"
            class="disabled:text-disabled disabled:opacity-100"
            disabled
          >
            {{ $t("COMMON.LOADING_SERVICE") }}
          </color-button>
        </slot>
      </template>
      <slot v-else-if="showReload && clientCanRunService" name="reload">
        <lf-link
          class="reload inline-block"
          @click.stop="showConfirmModal = true"
        >
          <icon-button icon="reload" data-cy="reload" />
        </lf-link>
      </slot>
      <icon-base v-if="!clientCanRunService" data-cy="locked" icon="lock" />
    </div>
  </access-control>
  <div class="flex space-x-2">
    <slot name="expand-all" />
    <slot name="download" />
    <download-service-button
      v-if="downloadData && serviceName !== 'CFA'"
      class="ml-2 download-service-button"
      :data="downloadData"
      :name="fileName"
    >
      <template #download-options>
        <slot name="download-options" />
      </template>
    </download-service-button>
  </div>
  <confirm-modal
    v-if="showConfirmModal"
    :title="modalTitle"
    :description="modalDescription"
    :warnings="warnings"
    :confirm-button="$t('COMMON.CONFIRM')"
    confirm-button-type="success"
    :close="() => (showConfirmModal = false)"
    @confirm="onConfirm"
  />
</template>

<script lang="ts">
import type { Role } from "@/models/options";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate, getServiceFileName } from "@/helpers/formatting";
import { useJsonTreeStateStore } from "@/stores/serviceJsonViewer";
import { storeToRefs } from "pinia";
import { transformServiceNameToExpectedString } from "@/helpers/services";
import * as Sentry from "@sentry/vue";
import { useDeals } from "@/hooks/deals";
import { useServices } from "@/hooks/services";
import {
  CATEGORY_SERVICE,
  MESSAGE_START_SERVICE
} from "@/helpers/constants/sentry";
</script>

<script
  setup
  lang="ts"
  generic="TDownloadData extends any[] | Record<string, any> | null"
>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import IconCurlyBraces from "@/components//icons/IconCurlyBraces.vue";
import {
  COMMERCIAL_DATA_STATUS_NOT_STARTED,
  LENDFLOW_ROLE_GROUP,
  CLIENT_ROLE_GROUP,
  CLIENT_UNDERWRITER,
  CLIENT_ADMIN,
  CLIENT_ANALYST,
  ANALYST,
  WORKFLOW_CATEGORIES,
  PROVIDER_EXPERIAN_BUSINESS_MATCH_QUICK_SEARCH
} from "@/helpers/constants";
import IconButton from "@/components/ui/buttons/IconButton.vue";

const props = withDefaults(
  defineProps<{
    confirmHandler: () => void;
    date?: string;
    status?: string;
    downloadData?: TDownloadData;
    serviceName: string;
    serviceType?: string | null;
    isLoading?: boolean;
    description?: string;
    confirmModalButtonDisabled?: boolean;
    title?: string;
    showReload?: boolean;
    confirmButtonText?: string;
    roles?: Role[] | null;
    hasJsonView?: boolean;
    providerName?: string;
    canRunService?: boolean | undefined;
    buttonReloads?: boolean;
  }>(),
  {
    date: "",
    status: "",
    description: "",
    title: "",
    showReload: true,
    confirmButtonText: "",
    providerName: "",
    canRunService: undefined,
    buttonReloads: false
  }
);

const { t } = useI18n();
const { activeDeal: deal, canEditDealApplicationAndUnderwriting } = useDeals();
const { isSubServiceAvailable } = useServices();
const serviceJsonTreeStateStore = useJsonTreeStateStore();
const { isJsonTreeShown } = storeToRefs(serviceJsonTreeStateStore);

const showConfirmModal = ref(false);

const excludedRoles = computed(() =>
  canEditDealApplicationAndUnderwriting.value
    ? [CLIENT_ANALYST, ANALYST]
    : [ANALYST, ...CLIENT_ROLE_GROUP]
);

const modalDescription = computed(() => {
  if (!props.date) {
    if (props.description) {
      return props.description;
    }
    return t("DEALS.STAGE_GATES.CONFIRM_SERVICE_START");
  }
  try {
    const [date, time] = props.date.split(" ");
    const [year, month, day] = date.split("-");
    const [hours, minutes, seconds] = time.split(":");

    // Using Date.UTC ensures we have the correct Date value format, new Date adjusts for local timezone.
    const dateToFormat = new Date(
      Date.UTC(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hours),
        Number(minutes),
        Number(seconds)
      )
    );

    return t("DEALS.STAGE_GATES.CONFIRM_SERVICE_REFRESH", {
      date: formatDate(dateToFormat)
    });
  } catch (error) {
    if (!Date.parse(props.date)) {
      return "-";
    }
    // fallback for valid date values
    return t("DEALS.STAGE_GATES.CONFIRM_SERVICE_REFRESH", {
      date: formatDate(props.date)
    });
  }
});

const dealHasEIN = computed(
  () =>
    !!deal.value?.business?.piis?.filter(
      ({ key, value }) => key === "EIN" && value
    )?.length
);

const noEINWarning = computed(() =>
  !dealHasEIN.value &&
  props.serviceType === "Experian" &&
  !(props.providerName === PROVIDER_EXPERIAN_BUSINESS_MATCH_QUICK_SEARCH)
    ? t("DEALS.DEAL_PROGRESS.NO_EIN")
    : ""
);

const clientCanRunService = computed(() => {
  if (deal.value.workflow === WORKFLOW_CATEGORIES[0].id) {
    return true;
  }

  if (typeof props.canRunService === "boolean") {
    return props.canRunService;
  }
  const subserviceName = transformServiceNameToExpectedString(
    props.providerName
  );
  return subserviceName ? isSubServiceAvailable(subserviceName) : false;
});

const allowedRoles = computed(() => {
  if (props.roles) {
    return props.roles;
  }
  // equipment rental and funding flow
  let erAndFundingRoles: Role[] = [...LENDFLOW_ROLE_GROUP];
  if (deal.value?.partner?.client?.can_create_underwriter) {
    erAndFundingRoles = [
      ...erAndFundingRoles,
      CLIENT_UNDERWRITER,
      CLIENT_ADMIN
    ];
  }
  return erAndFundingRoles;
});

const warnings = computed(() => [noEINWarning.value]);

const modalTitle = computed(() => {
  if (props.title && !props.date) {
    return props.title;
  }
  return props.date ? "Refresh Service" : "Start Service";
});

const onConfirm = () => {
  Sentry.addBreadcrumb({
    category: CATEGORY_SERVICE,
    message: MESSAGE_START_SERVICE.replace(":service", props.serviceName)
  });
  props.confirmHandler();
  showConfirmModal.value = false;
};
const fileName = getServiceFileName(
  deal.value?.partner?.client?.name || "",
  deal.value?.business?.business_legal_name,
  props?.serviceType || "",
  props?.serviceName
);

const showConfirm = () => {
  showConfirmModal.value = true;
};

defineExpose({ showConfirm });
</script>
<style>
.active.icon {
  background-color: #ebf0fb;
}

.active .icon-base {
  @apply text-primary;
}

.download-service-button .download-button {
  padding: 0;
}
</style>

import type {
  AvailableServices,
  IExpandedServiceData,
  BusinessCreditService,
  IBusinessCreditServices
} from "@/models/options";
import { computed, readonly, ref, watch, type Ref } from "vue";
import { useStore } from "vuex";
import { useDeals } from "./deals";
import type { PROVIDER_OCROLUS_CFA_V2 } from "@/helpers/constants";
import {
  LENDFLOW_ROLE_GROUP,
  CLIENT_UNDERWRITER,
  CLIENT_ADMIN,
  ANALYST,
  PROVIDER_OCROLUS_CFA
} from "@/helpers/constants";
import type { IUser } from "@/models/users";
import { useAuth } from "./auth";
import type {
  IExperianBusinessMatchResponseEntityCA,
  IExperianBusinessMatchResponseEntityUS
} from "@/models/applications";
import { useI18n } from "vue-i18n";
import { checkIsResponseCanadian, formatedAddress } from "@/helpers/services";
import { getObjectKeys } from "@/helpers/common";

export const useServices = () => {
  const { getters } = useStore();
  const { isLendflowUser } = useAuth();

  const businessCreditServices = computed<IBusinessCreditServices>(
    () => getters["options/businessCreditServices"]
  );

  const { activeDeal } = useDeals();

  const isServiceAvailable = (
    serviceName: keyof typeof businessCreditServices.value
  ) =>
    activeDeal.value?.partner?.client?.business_credit_services?.some(
      (service) =>
        Object.keys(businessCreditServices.value[serviceName]).includes(service)
    );

  const isSubServiceAvailable = (subServiceName: BusinessCreditService) =>
    !!activeDeal.value?.partner?.client?.business_credit_services?.includes(
      subServiceName
    );

  const canRunService = () => {
    if (isLendflowUser) {
      return true;
    }
    const user = computed<IUser | null>(() => getters["auth/user"]);

    const EMPLOYEES_GROUP = LENDFLOW_ROLE_GROUP.filter(
      (role) => role !== ANALYST
    );

    const allowedRoles = computed(() => {
      // equipment rental and funding flow
      let erAndFundingRoles = [...EMPLOYEES_GROUP];
      if (activeDeal.value?.partner?.client?.can_create_underwriter) {
        erAndFundingRoles = [
          ...erAndFundingRoles,
          CLIENT_UNDERWRITER,
          CLIENT_ADMIN
        ];
      }
      return erAndFundingRoles;
    });
    return !!user.value?.roles.some((role) =>
      allowedRoles.value.includes(role)
    );
  };

  return {
    isServiceAvailable,
    isSubServiceAvailable,
    canRunService: canRunService()
  };
};

export const useServiceDataExpanding = (serviceName: AvailableServices) => {
  const { getters, commit } = useStore();

  const expandedServiceData = computed<IExpandedServiceData[]>(
    () => getters["options/expandedServiceData"]
  );

  const serviceDataExpand = computed(
    () =>
      expandedServiceData.value?.find(
        (item) => item.service === serviceName
      ) || {
        service: serviceName as AvailableServices,
        baseDataExpanded: false,
        additionalDataExpanded: false
      }
  );

  const toggleAdditionalServiceContent = async () => {
    if (!serviceDataExpand.value.baseDataExpanded) {
      await commit("options/setExpandedContent", {
        service: serviceName,
        baseDataExpanded: true
      });
    }

    commit("options/setExpandedContent", {
      service: serviceName,
      additionalDataExpanded: !serviceDataExpand.value.additionalDataExpanded
    });
  };

  return { serviceDataExpand, toggleAdditionalServiceContent };
};

export const getExperianMatchData = (
  candidate:
    | IExperianBusinessMatchResponseEntityUS
    | IExperianBusinessMatchResponseEntityCA
    | null
) => {
  const { t } = useI18n();
  if (!candidate) {
    return [];
  }

  if (!checkIsResponseCanadian(candidate)) {
    const bin = {
      name: t("DEALS.EXPERIAN_MATCH.BIN"),
      result: candidate?.bin || "-",
      showActionButton: true
    };

    const businessName = {
      name: t("DEALS.EXPERIAN_MATCH.BUSINESS_NAME"),
      result: candidate?.businessName || "-",
      showActionButton: true
    };

    const phone = {
      name: t("DEALS.EXPERIAN_MATCH.PHONE"),
      result: candidate?.phone || "-",
      showActionButton: true
    };

    const address = {
      name: t("DEALS.EXPERIAN_MATCH.ADDRESS"),
      result: formatedAddress(candidate),
      showActionButton: true
    };

    const numberOfTradelines = {
      name: t("DEALS.EXPERIAN_MATCH.NUMBER_OF_TRADELINES"),
      result: candidate?.numberOfTradelines || "-"
    };

    const financialStatementIndicator = {
      name: t("DEALS.EXPERIAN_MATCH.FINANCIAL_STATEMENT_INDICATOR"),
      result: `${candidate?.financialStatementIndicator}`
    };

    const keyFactsIndicator = {
      name: t("DEALS.EXPERIAN_MATCH.KEY_FACTS_INDICATOR"),
      result: `${candidate?.keyFactsIndicator}`
    };

    const inquiryIndicator = {
      name: t("DEALS.EXPERIAN_MATCH.INQUIRY_INDICATOR"),
      result: `${candidate?.inquiryIndicator}`
    };

    const bankDataIndicator = {
      name: t("DEALS.EXPERIAN_MATCH.BANK_DATA_INDICATOR"),
      result: `${candidate?.bankDataIndicator}`
    };

    const governmentDataIndicator = {
      name: t("DEALS.EXPERIAN_MATCH.GOVERNMENT_DATA_INDICATOR"),
      result: `${candidate?.governmentDataIndicator}`
    };

    const executiveSummaryIndicator = {
      name: t("DEALS.EXPERIAN_MATCH.EXECUTIVE_SUMMARY_INDICATOR"),
      result: `${candidate?.executiveSummaryIndicator}`
    };

    const uccIndicator = {
      name: t("DEALS.EXPERIAN_MATCH.UCC_INDICATOR"),
      result: `${candidate?.uccIndicator}`
    };

    const matchingNameAndAddress = {
      name: t("DEALS.EXPERIAN_MATCH.MATCHING_NAME_AND_ADDRESS"),
      result: `${!!candidate?.matchingNameAndAddress}`
    };

    const businessGeocode = {
      name: t("DEALS.EXPERIAN_MATCH.BUSINESS_GEOCODE"),
      result: "true"
    };

    return [
      bin,
      businessName,
      phone,
      address,
      financialStatementIndicator,
      numberOfTradelines,
      keyFactsIndicator,
      inquiryIndicator,
      bankDataIndicator,
      governmentDataIndicator,
      executiveSummaryIndicator,
      uccIndicator,
      matchingNameAndAddress,
      businessGeocode
    ];
  }

  const phone = {
    name: t("DEALS.EXPERIAN_MATCH.PHONE"),
    result: candidate?.Phone || "-",
    showActionButton: true
  };

  const gbin = {
    name: t("DEALS.EXPERIAN_MATCH.GBIN"),
    result: candidate?.gbin || "-",
    showActionButton: true
  };

  const rbbid = {
    name: t("DEALS.EXPERIAN_MATCH.RBBID"),
    result: candidate?.rbbid || "-",
    showActionButton: false
  };

  const businessName = {
    name: t("DEALS.EXPERIAN_MATCH.BUSINESS_NAME"),
    result: candidate?.name || "-",
    showActionButton: true
  };

  const reliabilityCode = {
    name: t("DEALS.EXPERIAN_MATCH.RELIABILITY_SCORE_LABEL"),
    result: candidate?.matchReliabilityCode || "-",
    showActionButton: false
  };

  const creditIndicator = {
    name: t("DEALS.EXPERIAN_MATCH.CREDIT_INDICATOR"),
    result: String(candidate?.creditIndicator) || "-",
    showActionButton: false
  };

  const address = {
    name: t("DEALS.EXPERIAN_MATCH.ADDRESS"),
    result: formatedAddress(candidate),
    showActionButton: true
  };

  return [
    phone,
    gbin,
    rbbid,
    businessName,
    reliabilityCode,
    creditIndicator,
    address
  ];
};

export const useSectionsOpen = ({
  allSectionsOpen,
  isOpen
}: {
  allSectionsOpen: Ref<boolean>;
  isOpen: Ref<boolean> | Ref<Record<string, boolean>>;
}) => {
  watch(allSectionsOpen, (openAllSections: boolean) => {
    if (typeof isOpen.value === "boolean") {
      isOpen.value = openAllSections;
      return;
    }
    getObjectKeys(isOpen.value).forEach((key) => {
      isOpen.value[key] = openAllSections;
    });
  });
};

const isOcrolusLegacyVersion = ref(true);

const setIsOcrolusLegacyVersionFlag = (
  version: typeof PROVIDER_OCROLUS_CFA | typeof PROVIDER_OCROLUS_CFA_V2
) => {
  isOcrolusLegacyVersion.value = version === PROVIDER_OCROLUS_CFA;
};

export const useOcrolusService = () => {
  return {
    isLegacy: readonly(isOcrolusLegacyVersion),
    setIsOcrolusLegacyVersionFlag
  };
};

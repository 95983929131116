import type { JsonViewerData } from "@/models/jsonViewer";
import { produce } from "immer";
import { defineStore } from "pinia";

interface JsonTreeState {
  isShown: boolean;
  isReady: boolean;
}

interface ServicesJson {
  servicesJsonStatusesMap: {
    [key: string]: JsonTreeState;
  };
  jsonSearchValue: string;
  currentSearchResultToShow: number;
  totalSearchResultsFound: number;
  isEditJsonDisabled: Record<string, boolean>;
  jsonEditedData: JsonViewerData | null;
}
const getDefaultState = (): ServicesJson => ({
  servicesJsonStatusesMap: {},
  jsonSearchValue: "",
  currentSearchResultToShow: 0,
  totalSearchResultsFound: 0,
  isEditJsonDisabled: {},
  jsonEditedData: null
});

export const useJsonTreeStateStore = defineStore("jsonTrees", {
  state: getDefaultState,
  getters: {
    searchValue: ({ jsonSearchValue }) => jsonSearchValue,
    activeSearchResultIndex: ({ currentSearchResultToShow }) =>
      currentSearchResultToShow,
    searchResultsFound: ({ totalSearchResultsFound }) =>
      totalSearchResultsFound,
    servicesJsonStatuses: ({ servicesJsonStatusesMap }) =>
      servicesJsonStatusesMap,
    isJsonTreeShown: ({ servicesJsonStatusesMap }) => {
      return (jsonServiceName: string | undefined) =>
        !!(
          jsonServiceName &&
          servicesJsonStatusesMap[
            jsonServiceName as keyof typeof servicesJsonStatusesMap
          ]?.isShown
        );
    }
  },
  actions: {
    hideServiceJsonTreeView(jsonServiceName: string) {
      const jsonServiceState = this.servicesJsonStatuses[jsonServiceName];
      if (!jsonServiceState) {
        return;
      }
      this.servicesJsonStatusesMap = produce(
        this.servicesJsonStatuses,
        (draftJsonStatuses) => ({
          ...draftJsonStatuses,
          [jsonServiceName]: {
            isReady: true,
            isShown: false
          }
        })
      );
    },
    updateServiceJsonStatus(jsonServiceName: string) {
      const jsonServiceState = this.servicesJsonStatuses[jsonServiceName];
      if (jsonServiceState) {
        jsonServiceState.isShown = !jsonServiceState.isShown;
        if (!jsonServiceState.isReady) {
          jsonServiceState.isReady = true;
        }
      } else {
        this.servicesJsonStatusesMap = produce(
          this.servicesJsonStatuses,
          (draftJsonStatuses) => ({
            ...draftJsonStatuses,
            [jsonServiceName]: {
              isReady: true,
              isShown: true
            }
          })
        );
      }
    },
    resetServiceJsonStatus() {
      this.servicesJsonStatusesMap = produce(
        this.servicesJsonStatuses,
        (draftJsonStatuses) => {
          const keys = Object.keys(
            draftJsonStatuses
          ) as (keyof typeof draftJsonStatuses)[];
          keys.forEach((key) => {
            draftJsonStatuses[key].isReady = false;
            draftJsonStatuses[key].isShown = false;
          });
        }
      );
    },
    updateSearchValue(value: string) {
      this.jsonSearchValue = value;
      this.currentSearchResultToShow = 0;
    },
    updateSearchResultToShow(value: number) {
      if (this.currentSearchResultToShow + value < 0) {
        return;
      }
      this.currentSearchResultToShow = Math.min(
        this.currentSearchResultToShow + value,
        this.totalSearchResultsFound
      );
    },
    updateTotalSearchResultsFound(value: number) {
      this.totalSearchResultsFound = Math.max(value, 0);
    },
    resetSearchResultToShow() {
      this.currentSearchResultToShow = 0;
    },
    resetSearchValues() {
      this.jsonSearchValue = "";
      this.currentSearchResultToShow = 0;
      this.totalSearchResultsFound = 0;
    }
  }
});
